import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Spinner = ({ size }) => (
  <Spin size={size} />
);

Spinner.propTypes = {
  size: PropTypes.number,
};

Spinner.defaultProps = {
  size: 24,
};

const Spin = styled.span`
  border-radius: 50%;
  width: 1em;
  height: 1em;
  display: inline-block;
  position: relative;
  text-indent: -9999em;
  border: 0.1em solid rgba(94, 94, 94, 0.2);
  border-left-color: var(--primaryColor);
  transform: translateZ(0);
  animation: load 1.1s infinite linear;
  font-size: ${props => props.size}px;

  &:after {
    border-radius: 50%;
    width: 1em;
    height: 1em;
  }

  @keyframes load {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Spinner;
