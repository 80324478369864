/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,react/no-danger */
import React from 'react';
import styled from 'styled-components';
import { compose, path } from 'ramda';
import withCustomLayout from '../enhancers/withCustomLayout';
import { BLUE, DARK_SCHEME } from '../constants/colors';
import {
  CONTAINER_WIDTH, MOBILE, media, CONTAINER_LAPTOP_WIDTH,
} from '../constants/screens';
import withStickyHeader from '../enhancers/withStickyHeader';
import { cookiebotShow, cookiebotWithdraw, renderFrame } from '../utils/cookiebot';
import Spinner from '../components/common/spinner';
import { LayoutContext } from '../components/layout';

class PrivacyPolicyPage extends React.Component {
  state = {
    loading: true,
    consentReport: '',
  };

  consentTypes = ['marketing', 'necessary', 'preferences', 'statistics'];

  constructor() {
    super();
    this.forceUpdater = this.forceUpdater.bind(this);
  }

  componentDidMount() {
    const { env: { COOKIEBOT_DOMAIN_GROUP_ID: domainGroupId } } = this.context;

    if (typeof window !== 'undefined') {
      window.addEventListener('CookiebotOnAccept', this.forceUpdater);
      window.addEventListener('CookiebotOnDecline', this.forceUpdater);

      const consentStateObserver = setInterval(() => {
        if (typeof window.Cookiebot !== 'undefined') {
          clearInterval(consentStateObserver);
          this.forceUpdater();
        }
      }, 1000);
    }

    renderFrame(state => this.setState(state), domainGroupId);
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('CookiebotOnAccept', this.forceUpdater);
      window.removeEventListener('CookiebotOnDecline', this.forceUpdater);
    }
  }

  forceUpdater() {
    this.forceUpdate();
  }

  renderAllowedCookies() {
    const allowedCookies = this.consentTypes.filter(
      consentType => typeof window === 'object' && path(['Cookiebot', 'consent', consentType], window),
    );
    if (allowedCookies.length === 0) {
      return (
        <div>
          All cookies are forbidden
        </div>
      );
    }
    return (
      <div>
        You allowed current types of cookies:
        {` ${allowedCookies.join(', ')}`}
      </div>
    );
  }


  render() {
    const { loading, consentReport } = this.state;

    return (
      <Root>
        { loading ? <Spinner size={30} />
          : (
            <Content>
              <h2>Cookie policy</h2>
              <h4> Your current consent status</h4>
              <div>
                {this.renderAllowedCookies()}
                <StyledButton onClick={cookiebotShow}>Change your consent</StyledButton>
                {' '}
              |
                {' '}
                <StyledButton onClick={cookiebotWithdraw}>Withdraw your consent</StyledButton>
              </div>
              <h4>What this policy covers</h4>
              <div dangerouslySetInnerHTML={{ __html: consentReport }} />
            </Content>
          )
        }
      </Root>
    );
  }
}

PrivacyPolicyPage.contextType = LayoutContext;

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 140px 0 0 0;
  min-height: 400px;
`;

const StyledButton = styled.button`
  display: inline;
  border: none;
  background: none;
  padding: 0;
  color: ${BLUE};
  cursor: pointer;
  font-size: inherit;
`;

const Content = styled.div`
  width: 100%;
  max-width: ${CONTAINER_WIDTH};
  padding: 0 40px;
  font-size: 16px;
  line-height: 2em;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `}

  h2 {
    font-size: 32px;
  }

  h4 {
    font-size: 22px;
    margin: 20px 0;
  }

  h6 {
    margin: 20px 0;
  }

  p {
    line-height: 20px;
    text-align: justify;
    margin: 20px 0;
  }

  ol {
    list-style: decimal inside;
    
    p {
      display: inline;
    }
  }

  ul {
    list-style: disc inside;
    
    p {
      display: inline;
    }
  }

  li {
    margin: 20px 0;
  }

  a {
    text-decoration: none;
  }
  
  .CookieDeclarationType {
    line-height: 1.8em;
    margin-bottom: 30px;
  }
  .CookieDeclarationTypeHeader, .CookieDeclarationTableHeader {
    font-family: AvenirNextLTProBold;
  }
  .CookieDeclarationTypeHeader {
    margin-bottom: 24px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    padding: 0 20px;
  }
  
  border: ${props => (props.loading ? '1px solid red' : 'none')}
`;

export default compose(
  withStickyHeader(),
  withCustomLayout({ scheme: DARK_SCHEME }),
)(PrivacyPolicyPage);
