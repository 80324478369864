export const getConsentReportHTML = (myDoc, stateUpdater) => {
  if (myDoc.readyState === 'complete') {
    setTimeout(() => {
      const reportDivs = myDoc.body.getElementsByTagName('div');
      const reportDiv = reportDivs && reportDivs.length && reportDivs[0];
      const consentReport = reportDiv && reportDiv.innerHTML;
      stateUpdater({
        loading: false,
        consentReport,
      });
    }, 1000);
  } else {
    // This will be continiously called until the iFrame is ready
    setTimeout(() => { getConsentReportHTML(myDoc, stateUpdater); });
  }
};

export const renderFrame = (stateUpdater, domainGroupId) => {
  const cookiebotTextFrame = document.createElement('iframe');
  cookiebotTextFrame.setAttribute('width', '100%');
  cookiebotTextFrame.id = 'cookiebotTextFrame';

  const zone = document.createElement('div');
  document.body.appendChild(zone);
  zone.style.display = 'none';
  zone.innerHTML = '';
  zone.appendChild(cookiebotTextFrame);

  const url = `https://consent.cookiebot.com/${domainGroupId}/cd.js`;

  const cookiebotTextFrameHTML = `
    <html>
      <head>
      </head>
      <body>
        <script type="text/javascript" src='${url}'></script>
      </body>
    </html>
  `;

  let cookiebotTextFrameDoc = cookiebotTextFrame.document;
  if (cookiebotTextFrame.contentDocument) {
    cookiebotTextFrameDoc = cookiebotTextFrame.contentDocument;
  } else if (cookiebotTextFrame.contentWindow) {
    cookiebotTextFrameDoc = cookiebotTextFrame.contentWindow.document;
  }

  cookiebotTextFrameDoc.open();
  cookiebotTextFrameDoc.writeln(cookiebotTextFrameHTML);
  cookiebotTextFrameDoc.close();

  getConsentReportHTML(cookiebotTextFrameDoc, stateUpdater);
};

export const cookiebotShow = () => {
  if (typeof window !== 'undefined' && typeof Cookiebot !== 'undefined') {
    window.Cookiebot.show();
  }
};

export const cookiebotWithdraw = () => {
  if (typeof window !== 'undefined' && typeof Cookiebot !== 'undefined') {
    window.Cookiebot.withdraw();
  }
};
